import React from 'react';
import EChartsGradientStackedAreaChart from '../EChartsGradientStackedAreaChart';

const DriftPage = () => {
    // Sample axis data, replace these with actual props or state as needed
    const xaxis = 'index';

    return (
        <div className="p-5 bg-gray-100">
            {/* <h1 className="text-3xl font-bold text-center mb-5">Dashboard</h1> */}
            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
                <div className="bg-white p-4 shadow rounded-lg">
                    <EChartsGradientStackedAreaChart categories='index' seriesNames={['drift']} />
                </div>
            </div>
        </div>
    );
};

export default DriftPage;
