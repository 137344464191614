import * as geolib from 'geolib'
import { format } from 'maplibre-gl'
import Papa from 'papaparse'

export default function parseCSV (
  file,
  setData,
  setMinAltitude,
  setFirstTimestamp,
  setPath,
  setPathIndexes,
  setTotalDistance,
  setMileMarkers,
  setHandrailCoordinates
) {
  Papa.parse(file, {
    header: true,
    dynamicTyping: true,
    complete: results => {
      // Filter rows to remove any with NaN values in critical fields
      const processResults = results => {
        let lastMilestone = 0
        const mileMarkers = []
        const formattedData = []

        results.data.forEach((d, index) => {
          if (!isValidDataPoint(d)) return

          const point = {
            ...d,
            index,
            msrs_lng: parseFloat(d.msrs_lng),
            msrs_lat: parseFloat(d.msrs_lat),
            gps_lng: parseFloat(d.gps_lng),
            gps_lat: parseFloat(d.gps_lat),
            altitude: parseFloat(d.altitude),
            timestamp: new Date(d.Timestamp).getTime()
          }

          // Correct zero GPS coordinates with last known good values
          if (point.gps_lat === 0 || point.gps_lng === 0) {
            const lastPoint =
              formattedData.length > 0
                ? formattedData[formattedData.length - 1]
                : null
            point.gps_lat = lastPoint ? lastPoint.gps_lat : point.gps_lat
            point.gps_lng = lastPoint ? lastPoint.gps_lng : point.gps_lng
          }

          // Calculate running total distance
          if (formattedData.length > 0) {
            const lastPoint = formattedData[formattedData.length - 1]
            point.running_total_distance =
              lastPoint.running_total_distance +
              computeDistance(lastPoint, point)
          } else {
            point.running_total_distance = 0
          }

          // Check for milestone crossing
          if (Math.floor(point.running_total_distance) > lastMilestone) {
            lastMilestone = Math.floor(point.running_total_distance)
            mileMarkers.push({
              longitude: point.msrs_lng,
              latitude: point.msrs_lat,
              milestone: lastMilestone,
              label: `Mile ${lastMilestone}`
            })
          }

          formattedData.push(point)
        })

        return { formattedData, mileMarkers }
      }

      let { formattedData, mileMarkers } = processResults(results)

      setMileMarkers(mileMarkers)

      formattedData = addCEPCoordinates(formattedData)

      // Add camera_heading as a smoothed version of witmotion_heading
      formattedData.forEach((d, i, arr) => {
        const numNeighbors = 20 // Number of neighbors on each side to consider
        if (d.raw_heading) {
          let sum = d.raw_heading
          let count = 1

          for (let j = 1; j <= numNeighbors; j++) {
            if (arr[i - j]) {
              sum += arr[i - j].raw_heading
              count++
            }
            if (arr[i + j]) {
              sum += arr[i + j].raw_heading
              count++
            }
          }

          d.camera_heading = sum / count // Average heading
        } else {
          console.log('no raw_heading')
          let sum = d.witmotion_heading
          let count = 1

          for (let j = 1; j <= numNeighbors; j++) {
            if (arr[i - j]) {
              sum += arr[i - j].witmotion_heading
              count++
            }
            if (arr[i + j]) {
              sum += arr[i + j].witmotion_heading
              count++
            }
          }

          d.camera_heading = sum / count // Average heading
        }
      })

      formattedData.forEach((d, i, arr) => {
        // Convert the timestamp into a Date object if it's stored as a string
        let currentTimestamp = new Date(d.timestamp)

        // Use the timestamp from the first entry as the start time
        let startTime = i === 0 ? currentTimestamp : new Date(arr[0].timestamp)

        // Calculate time elapsed in milliseconds
        let timeElapsed = currentTimestamp - startTime

        // Convert timeElapsed from milliseconds to hours
        timeElapsed = timeElapsed / (1000 * 60 * 60) // 1000 ms in a second, 60 seconds in a minute, 60 minutes in an hour

        // Add the time elapsed since the start to the data object, rounded to two decimal places if needed
        d.time_elapsed = Math.round(timeElapsed * 100) / 100
      })

      calculateGPSDistances(formattedData)

      formattedData.forEach((d, i, arr) => {
        // Initialize previousCepDrift only if it's the first iteration or when needed
        let previousCepDrift = i === 0 ? 0 : arr[i - 1].cep_drift
        let cepDrift = previousCepDrift // Default cepDrift to previous cepDrift

        if (d.sat_use > 4) {
          cepDrift = geolib.getDistance(
            {
              latitude: parseFloat(d.cep_lat),
              longitude: parseFloat(d.cep_lng)
            },
            {
              latitude: parseFloat(d.gps_lat),
              longitude: parseFloat(d.gps_lng)
            }
          )
          previousCepDrift = cepDrift
        } else {
          cepDrift = previousCepDrift
        }

        // Add the calculated or carried forward cepDrift to the data object
        d.cep_drift = cepDrift
      })

      let previousDrift = 0
      formattedData.forEach((d, i, arr) => {
        // Initialize previousDrift only if it's the first iteration or when needed
        let drift = 0 // Default drift to previous drift

        if (d.sat_use > 4) {
          drift = geolib.getDistance(
            {
              latitude: parseFloat(d.msrs_lat),
              longitude: parseFloat(d.msrs_lng)
            },
            {
              latitude: parseFloat(d.gps_lat),
              longitude: parseFloat(d.gps_lng)
            }
          )
          previousDrift = drift
        } else {
          drift = previousDrift
        }

        // Add the calculated or carried forward drift to the data object
        d.drift = drift
      })

      calculateDriftRate(formattedData)
      calculateDriftChange(formattedData)

      const handrailCoordinates = []
      formattedData.forEach((d, i, arr) => {
        if (d.handrail_lat && d.handrail_lon) {
          // Try parsing the string as JSON to get the nested array
          try {
            handrailCoordinates.push([
              parseFloat(d.handrail_lon),
              parseFloat(d.handrail_lat),
              parseFloat(d.handrail_offset)
            ])
          } catch (error) {
            // Handle parsing error (optional)
            console.error('Error parsing closest_feature:', error)
          }
        }
      })

      const handrailOffsets = []
      formattedData.forEach((d, i, arr) => {
        if (true) {
          handrailOffsets.push(d.handrail_offset)
        }
      })

      console.log('Handrail offsets', handrailOffsets)

      // Calculate the minimum and maximum altitudes for scaling
      const altitudes = formattedData
        .map(d => d.altitude)
        .filter(alt => alt > 0)
      const minAlt = Math.min(...altitudes)
      const maxAlt = Math.max(...formattedData.map(d => d.altitude)) // Find the maximum altitude
      const scalingFactor = 1 / (maxAlt - minAlt)

      // Creating the path dataset for use with Deck.gl
      const dataset = [
        {
          id: 1,
          path: formattedData.map(d => [
            d.msrs_lng,
            d.msrs_lat,
            (d.altitude - minAlt) * 0.3048
          ]), // Assuming altitude is relevant
          timestamps: formattedData.map(d => d.index),
          color: [200, 0, 200]
        },
        {
          id: 2,
          path: formattedData.map(d => [
            d.gps_lng,
            d.gps_lat,
            (d.altitude - minAlt) * 0.3048
          ]), // Assuming GPS data is available
          timestamps: formattedData.map(d => d.index),
          color: formattedData.map(d => getColorBySatUse(d.sat_use))
        },
        {
          id: 3,
          path: formattedData.map(d => [
            d.msrs_lng,
            d.msrs_lat,
            (d.altitude - minAlt) * 0.3048 * scalingFactor
          ]), // Assuming GPS data is available
          timestamps: formattedData.map(d => d.index),
          color: formattedData.map(d => getColorBySatUse(d.drift))
        },
        {
          id: 4,
          path: formattedData.map(d => [d.gps_lng, d.gps_lat, 0]), // Assuming GPS data is available
          timestamps: formattedData.map(d => d.index),
          color: formattedData.map(d => getColorBySatUse(d.sat_use))
        },
        {
          id: 5,
          path: handrailCoordinates.map((d, index) => [d[0], d[1], 0]), // Assuming GPS data is available
          timestamps: handrailCoordinates.map((d, index) => index),
          color: handrailCoordinates.map(d => [255, 255, 255])
        },
        {
          id: 6,
          path: formattedData.map(d => [d.cep_lng, d.cep_lat, 0]), // Assuming GPS data is available
          timestamps: formattedData.map(d => d.index),
          color: formattedData.map(d => [255, 255, 255])
        }
      ]
      // console.log('Dataset:', dataset[0].path);
      const pathAnalysis = dataset[0].path.map(([longitude, latitude]) => ({
        latitude: latitude,
        longitude: longitude
      }))
      // console.log('Path analysis:', pathAnalysis);
      const calculateTotalDistance = path => {
        let totalDistance = 0
        for (let i = 1; i < path.length; i++) {
          // Check that both points have valid latitude and longitude
          if (isValidCoordinate(path[i - 1]) && isValidCoordinate(path[i])) {
            const distance = geolib.getDistance(path[i - 1], path[i])
            if (!isNaN(distance)) {
              totalDistance += distance
            } else {
              console.log('Problematic Points:', path[i - 1], path[i]) // Log problematic points
            }
          }
        }
        return totalDistance
      }

      const totalDistance = calculateTotalDistance(pathAnalysis) / 1609.34
      // console.log('Total distance is:', totalDistance, 'meters');

      setTotalDistance(totalDistance)
      setData(formattedData)
      setPath(dataset) // Assuming you use path for visualization
      setPathIndexes(dataset[0].timestamps) // Assuming this is for animation control
      setMinAltitude(minAlt)
      setFirstTimestamp(formattedData[0].timestamp)
      setHandrailCoordinates({
        coordinates: handrailCoordinates,
        offsets: handrailOffsets
      })

      // console.log('Dataset:', dataset);
      // console.log('Min altitude:', minAlt);
      // console.log('First timestamp:', formattedData[0].timestamp);
      console.log('Data:', formattedData)
    }
  })
}

function getColorBySatUse (sat_use) {
  // Ensure sat_use is within the expected range

  if (sat_use <= 4) {
    return [255, 0, 0] // Red
  } else if (sat_use <= 8) {
    return [255, 255, 0] // Yellow
  } else {
    return [0, 255, 0] // Green
  }
}

function addCEPCoordinates(formattedData) {
  // Check if formattedData is valid and not empty
  if (!formattedData || formattedData.length === 0) {
    console.error('No data available');
    return [];
  }

  // Find initial valid starting coordinates that are not (0,0)
  let startingIndex = formattedData.findIndex(
    d => d.gps_lat !== 0 && d.gps_lng !== 0
  );

  // Initialize default starting coordinates if no valid starting point is found
  let currentLat, currentLng;
  if (startingIndex === -1) {
    console.warn('No valid starting GPS coordinates found. Using default coordinates.');
    currentLat = 0; // Replace with a default latitude if needed
    currentLng = 0; // Replace with a default longitude if needed
    startingIndex = 0; // Start from the beginning of the array
  } else {
    currentLat = formattedData[startingIndex].gps_lat;
    currentLng = formattedData[startingIndex].gps_lng;
  }

  let currDist = 0;

  // Iterate over formattedData to calculate new cep_lat and cep_lng
  for (let index = 0; index < formattedData.length; index++) {
    let d = formattedData[index];

    if (index < startingIndex || currDist === d.jetson_doppler_distance) {
      // Before the start index or if coordinates are (0,0), skip updating
      d.cep_lat = currentLat;
      d.cep_lng = currentLng;
    } else {
      let newPoint;
      try {
        if (d.raw_heading) {
          newPoint = geolib.computeDestinationPoint(
            { latitude: currentLat, longitude: currentLng },
            d.jetson_doppler_distance,
            reverseCalculateYawCompass(d.raw_heading, d.handrail_offset)
          );
        } else {
          console.log('not raw_heading');
          newPoint = geolib.computeDestinationPoint(
            { latitude: currentLat, longitude: currentLng },
            d.jetson_doppler_distance,
            reverseCalculateYawCompass(d.witmotion_heading, d.handrail_offset)
          );
        }
      } catch (error) {
        console.error('Error computing destination point:', error);
        continue; // Skip this iteration if there's an error
      }

      d.cep_lat = newPoint.latitude;
      d.cep_lng = newPoint.longitude;

      // Update current coordinates for the next iteration
      currentLat = newPoint.latitude;
      currentLng = newPoint.longitude;
      currDist = d.jetson_doppler_distance;
    }
  }

  return formattedData;
}

const isValidCoordinate = ({ latitude, longitude }) => {
  return (
    isFinite(latitude) &&
    Math.abs(latitude) <= 90 &&
    isFinite(longitude) &&
    Math.abs(longitude) <= 180
  )
}

function reverseCalculateYawCompass (b, diff) {
  let a = (b - diff + 360) % 360
  return a
}

function calculateGPSDistances (data) {
  let previousCoords = null // To store coordinates of the previous row
  let previousDistance = 0 // To store the last calculated distance

  data.forEach((d, i) => {
    if (i === 0) {
      // No previous row for the first item, set distance to 0
      d.gps_distance = 0
    } else {
      // Calculate distance from the previous row's GPS coordinates to the current row's GPS coordinates
      const currentCoords = {
        latitude: parseFloat(d.gps_lat),
        longitude: parseFloat(d.gps_lng)
      }

      if (previousCoords) {
        if (
          previousCoords.latitude === currentCoords.latitude &&
          previousCoords.longitude === currentCoords.longitude
        ) {
          // Coordinates haven't changed, keep the previous distance
          d.gps_distance = previousDistance
        } else {
          // Coordinates have changed, calculate new distance
          d.gps_distance = geolib.getDistance(previousCoords, currentCoords)
          previousDistance = d.gps_distance // Update the previous distance
        }
      } else {
        // In case the previous row had no valid coordinates, set distance to 0
        d.gps_distance = 0
      }
    }

    // Update previousCoords for the next iteration
    previousCoords = {
      latitude: parseFloat(d.gps_lat),
      longitude: parseFloat(d.gps_lng)
    }
  })
}

function adjustDriftValues (data) {
  let previousDrift = 0 // To store drift of the previous row

  data.forEach(d => {
    if (Math.abs(d.drift - previousDrift) > 100) {
      // If the drift change is greater than 100 meters, use the previous row's drift
      d.drift = previousDrift
    } else {
      // Update previousDrift to the current row's drift if the change is reasonable
      previousDrift = d.drift
    }

    // Additionally, cap any drift value exceeding 500 meters to 10
    if (d.drift > 500) {
      d.drift = 10
    }
  })
}

function calculateDriftRate (data) {
  data.forEach((d, index) => {
    // Calculate the start and end indices for the previous 10 and next 10 rows
    let start = Math.max(0, index - 10)
    let end = Math.min(data.length - 1, index + 10)

    let sumChange = 0
    let count = 0

    // Loop from start to end and calculate sum of drift changes
    for (let i = start; i < end; i++) {
      if (i < data.length - 1) {
        // Ensure we do not go out of bounds
        let currentChange = data[i + 1].drift - data[i].drift
        sumChange += currentChange
        count++
      }
    }

    // Calculate average drift change if count is not zero
    d.drift_delta = count > 0 ? sumChange / count : 0
  })
}

function calculateDriftChange (data) {
  let previousDrift = data[0] ? data[0].drift : 0 // Initialize to the drift of the first item if available

  data.forEach((d, index) => {
    if (index === 0) {
      // For the first item, set drift_change to 0 as there's no previous item to compare
      d.drift_change = 0
    } else {
      // Calculate drift_change as the difference between the current and previous drift values
      d.drift_change = d.drift - previousDrift
    }

    // Update previousDrift to the current drift for the next iteration
    previousDrift = d.drift
  })
}

function isValidDataPoint (d) {
  return (
    !isNaN(parseFloat(d.msrs_lng)) &&
    !isNaN(parseFloat(d.msrs_lat)) &&
    !isNaN(parseFloat(d.gps_lng)) &&
    !isNaN(parseFloat(d.gps_lat)) &&
    !isNaN(parseFloat(d.altitude)) &&
    d.Timestamp &&
    new Date(d.Timestamp).getTime() > 0
  )
}

function computeDistance (lastPoint, currentPoint) {
  return (
    geolib.getDistance(
      { latitude: lastPoint.msrs_lat, longitude: lastPoint.msrs_lng },
      { latitude: currentPoint.msrs_lat, longitude: currentPoint.msrs_lng }
    ) / 1609.34
  ) // Convert meters to miles
}
