import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 dark:bg-gray-900">
      <div className="mx-auto w-full p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <a href="https://msrsnav.com/" className="flex items-center">
              <img src="/MSRS.svg" className="h-8 me-3" alt="MSRS Logo" />
              <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">MSRS</span>
            </a>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white">Resources</h2>
              <ul className="text-white dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <a href="https://www.msrsnav.com/team" className="hover:underline">Team</a>
                </li>
                <li>
                  <a href="https://www.msrsnav.com/contactus" className="hover:underline">Contact</a>
                </li>
                <li>
                  <a href="https://www.msrsnav.com/technical" className="hover:underline">Technical Info</a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white">Follow us</h2>
              <ul className="text-white dark:text-gray-400 font-medium">
                {/* <li className="mb-4">
                  <a href="https://github.com/themesberg/flowbite" className="hover:underline ">Github</a>
                </li> */}
                <li>
                  <a href="https://www.linkedin.com/company/100506718/admin/feed/posts/" className="hover:underline">LinkedIn</a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white">Legal</h2>
              <ul className="text-white dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <a href="https://www.msrsnav.com/legal" className="hover:underline">Privacy Policy</a>
                </li>
                <li>
                  <a href="https://www.msrsnav.com/legal" className="hover:underline">Terms & Conditions</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-white sm:text-center dark:text-gray-400">© 2024 <a href="https://www.msrsnav.com/" className="hover:underline">MSRS™</a>. Copyright © 2024 MSRS, LLC.  U.S. Patent No. 11,506,797.  Additional patent application(s) pending.  All rights reserved.
          </span>
          <div className="flex mt-4 sm:justify-center sm:mt-0">
            <a href="#" className="text-white hover:text-white dark:hover:text-white">
              <span className="sr-only">Facebook page</span>
              {/* SVG for Facebook */}
            </a>
            <a href="#" className="text-white hover:text-white dark:hover:text-white ms-5">
              <span className="sr-only">Discord community</span>
              {/* SVG for Discord */}
            </a>
            <a href="#" className="text-white hover:text-white dark:hover:text-white ms-5">
              <span className="sr-only">Twitter page</span>
              {/* SVG for Twitter */}
            </a>
            <a href="#" className="text-white hover:text-white dark:hover:text-white ms-5">
              <span className="sr-only">GitHub account</span>
              {/* SVG for GitHub */}
            </a>
            <a href="#" className="text-white hover:text-white dark:hover:text-white ms-5">
              <span className="sr-only">Dribbble account</span>
              {/* SVG for Dribbble */}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
