import React, { useContext, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { DataContext } from './DataContext';

const EChartsGradientStackedAreaChart = ({ categories, seriesNames }) => {
    const { data } = useContext(DataContext);
    const [yAxisScale, setYAxisScale] = useState({ min: 0, max: 1800 });

    if (!data || !Array.isArray(data) || data.length === 0) {
        return <p>No data available</p>;
    }

    // Update yAxis range based on slider input
    const handleSliderChange = (event) => {
        const { value } = event.target;
        setYAxisScale({ ...yAxisScale, max: Number(value) });
    };

    // ECharts configuration
    const options = {
        title: {
            text: 'Drift'
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: data.map(item => item[categories])
        },
        yAxis: {
            type: 'value',
            min: yAxisScale.min, // dynamic min value from state
            max: yAxisScale.max  // dynamic max value from state
        },
        series: seriesNames.map(name => ({
            name,
            type: 'line',
            stack: 'Total',
            areaStyle: { opacity: 0.8 },
            data: data.map(item => item[name])
        })),
        legend: {
            data: seriesNames
        }
    };

    return (
        <div style={{ width: '100%', height: '80vh' }}>
            <input
                type="range"
                min="0"
                max="500"  // Set maximum slider value (adjust based on your data range)
                value={yAxisScale.max}
                onChange={handleSliderChange}
            />
            <ReactECharts option={options} style={{ width: '100%', height: '100%' }} />
        </div>
    );
};

export default EChartsGradientStackedAreaChart;
