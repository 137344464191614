import React, { useState, useEffect, useContext } from 'react';
import { ref, listAll, getDownloadURL, uploadBytes, getStorage, deleteObject } from 'firebase/storage';
import app from './Firebase'; // Assuming you export `app` as `firebaseApp` from './Firebase'
import { DataContext } from './DataContext';
import UploadProgress from './UploadProgress';
import parseCSV from './parseCSV';
import { set } from 'firebase/database';

const storage = getStorage(app);

const FirebaseStorage = () => {
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [password, setPassword] = useState("");
    const [accessLevel, setAccessLevel] = useState(0);
    const { setData, setMinAltitude, setFirstTimestamp, setPath, setPathIndexes, setTotalDistance, setMileMarkers, setHandrailCoordinates } = useContext(DataContext);

	const loadDataToMap = async () => {
		if (!selectedFile) {
			alert("No file selected!");
			return;
		}
		setUploading(true);
	
		// Fetch the file content from the URL
		const response = await fetch(selectedFile.url);
		const fileText = await response.text();
        parseCSV(fileText, setData, setMinAltitude, setFirstTimestamp, setPath, setPathIndexes, setTotalDistance, setMileMarkers, setHandrailCoordinates);
        setUploading(false);
    };

    useEffect(() => {
        if (loading) {
            const fetchFiles = async () => {
                const storageRef = ref(storage);
                try {
                    const listResult = await listAll(storageRef);
                    const fileList = await Promise.all(listResult.items.map(async itemRef => {
                        const url = await getDownloadURL(itemRef);
                        return { name: itemRef.name, url };
                    }));
                    setFiles(fileList);
                } catch (error) {
                    console.error('Error fetching files:', error);
                }
                setLoading(false);
            };

            fetchFiles();
        }
    }, [loading]);

    const handleFileSelection = (event) => {
        const fileName = event.target.value;
        const file = files.find(f => f.name === fileName);
        setSelectedFile(file);
    };

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;
        const fileRef = ref(storage, file.name);
        setUploading(true);
        try {
            const snapshot = await uploadBytes(fileRef, file);
            const url = await getDownloadURL(snapshot.ref);
            const newFile = { name: file.name, url };
            setFiles(prev => [...prev, newFile]);
            setSelectedFile(newFile);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
        setUploading(false);
    };

    const handleDownload = () => {
        if (!selectedFile) return;
        const link = document.createElement('a');
        link.href = selectedFile.url;
        link.download = selectedFile.name;
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    const handleDelete = async () => {
        const password = prompt("Please enter the password to delete the file:");
        if (password === process.env.REACT_APP_MASTER_PASSWORD) {
            if (selectedFile) {
                const fileRef = ref(storage, selectedFile.name);
                try {
                    await deleteObject(fileRef);
                    setFiles(files.filter(file => file.name !== selectedFile.name));
                    setSelectedFile(null);
                    alert("File deleted successfully.");
                } catch (error) {
                    console.error('Error deleting file:', error);
                    alert("Failed to delete the file.");
                }
            }
        } else {
            alert("Incorrect password.");
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handlePasswordSubmit();
        }
    };

    const handlePasswordSubmit = () => {
        if (password === process.env.REACT_APP_GUEST_PASSWORD) {
            setAccessLevel(1);
        } else if (password === process.env.REACT_APP_INTERMEDIATE_PASSWORD) {
            setAccessLevel(2);
        } else if (password === process.env.REACT_APP_MASTER_PASSWORD) {
            setAccessLevel(3);
        } else {
            alert("Incorrect password.");
        }
    };

    return (
        <div className="p-4 bg-white rounded-lg shadow-lg mx-auto my-4">
            <h1 className="text-xl font-semibold mb-4">Cloud Files</h1>
            {accessLevel === 0 ? (
                <div>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter password"
                        className="mb-2 p-2 rounded border border-gray-300 w-full"
                        onKeyPress={handleKeyPress}
                    />
                    <button
                        onClick={handlePasswordSubmit}
                        className="bg-gray-800/70 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline font-mono text-sm"
                    >
                        Submit
                    </button>
                </div>
            ) : (
                <>
                    {accessLevel > 0 && (
                        <select onChange={handleFileSelection} value={selectedFile?.name || ''}
                                className="mb-4 p-2 rounded border border-gray-300 w-full">
                            <option value="">Select a file</option>
                            {files.map(file => (
                                <option key={file.name} value={file.name}>
                                    {file.name}
                                </option>
                            ))}
                        </select>
                    )}
                    <div className="flex space-x-2">
                        {accessLevel > 0 && (
                            <button onClick={loadDataToMap} 
                                    className="bg-gray-800/70 hover:bg-blue-700 text-white font-bold px-2 rounded focus:outline-none focus:shadow-outline font-mono text-sm"
                                    disabled={!selectedFile}>
                                Load Data to Map
                            </button>
                        )}
                        {accessLevel > 1 && (
                            <>
                                <button onClick={handleDownload}
                                        className="bg-gray-800/70 hover:bg-blue-700 text-white font-bold px-2 rounded focus:outline-none focus:shadow-outline font-mono text-sm"
                                        disabled={!selectedFile}>
                                    Download to Device
                                </button>
                                <label className="bg-gray-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline cursor-pointer font-mono text-sm">
                                    Upload to Cloud
                                    <input type="file" onChange={handleFileUpload} disabled={uploading}
                                           className="hidden"/>
                                </label>
                            </>
                        )}
                        {accessLevel > 2 && (
                            <button onClick={handleDelete}
                                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline font-mono text-sm"
                                    disabled={!selectedFile}>
                                Delete from Cloud
                            </button>
                        )}
                    </div>
                </>
            )}
            {uploading && <UploadProgress isUploading={uploading} />}
        </div>
    );
};

export default FirebaseStorage;

function getColorBySatUse(sat_use) {
    // Ensure sat_use is within the expected range
  
    if (sat_use <= 4) {
      return [255, 0, 0]; // Red
    } else if (sat_use <= 8) {
      return [255, 255, 0]; // Yellow
    } else {
      return [0, 255, 0]; // Green
    }
  }