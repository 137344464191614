import React, { useContext } from 'react';
import { DataContext } from './DataContext';
import parseCSV from './parseCSV';
import { set } from 'firebase/database';

const CSVUpload = () => {
  const { setData, setMinAltitude, setFirstTimestamp, setPath, setPathIndexes, setTotalDistance, setMileMarkers, setHandrailCoordinates } = useContext(DataContext);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    parseCSV(file, setData, setMinAltitude, setFirstTimestamp, setPath, setPathIndexes, setTotalDistance, setMileMarkers, setHandrailCoordinates);
  };

  return (
    <div>
        <input type="file" onChange={handleFileUpload} className='pr-5 bg-gray-300 text-black rounded shadow-lg font-mono text-xs' />
    </div>
  );
};

export default CSVUpload;

