import React, { useRef, useEffect, useContext } from 'react';
import mapboxgl from 'mapbox-gl';
import { MapboxOverlay } from '@deck.gl/mapbox';
import { ColumnLayer, PathLayer } from '@deck.gl/layers';
import 'mapbox-gl/dist/mapbox-gl.css';
import { DataContext } from './DataContext';

const CEPMap = () => {
  const mapContainerRefCep = useRef(null);
  const deckOverlayRefCep = useRef(null);
  const mapRefCep = useRef(null);
  const { data, path } = useContext(DataContext);

  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

  // Define layer configurations in a dictionary
  const layerConfigs = {
    cepLayer: {
        id: 'cep-layer',
        data,
        getPosition: d => [d.cep_lng, d.cep_lat],
        getFillColor: [255, 0, 0],
        getElevation: d => 1,
        elevationScale: 0.1,
        diskResolution: 12,
        radius: 100,
        extruded: true,
        pickable: true,
    },
    cepGpsPathLayer: {
        id: 'cep-gps-path-layer',
        data: [path[1]],  // This should be the data set from your state or context that includes the path information.
        getPath: d => d.path,  // Assuming each data entry has a 'path' property which is an array of coordinates.
        getColor: d => [0,255,0],  // Assuming each data entry includes a 'color' property defining the path color.
        getElevation: d => 0,  // Assuming the path is flat on the ground
        widthScale: 100,  // Adjust this value based on the desired thickness of the path.
        opacity: 1,
        pickable: true,
        capRounded: true,
        jointRounded: true,
    },
  };

  useEffect(() => {
    if (!mapContainerRefCep.current) return;
    const defaultCoordinates = [-80, 34]; // Fallback coordinates
    const initialCoordinates = data && data.length > 0 ? [data[0].msrs_lng, data[0].msrs_lat] : defaultCoordinates;
  
    const map = new mapboxgl.Map({
      container: mapContainerRefCep.current,
      style: 'mapbox://styles/frstylskier/clvd3y7d801gc01ql57zv1uos',
      accessToken: MAPBOX_TOKEN,
      center: initialCoordinates,
      zoom: data && data.length > 0 ? 14 : 2,
      pitch: data && data.length > 0 ? 15 : 0,
    });
    mapRefCep.current = map;

    map.on('load', () => {
      setupDeckOverlay(map);
    });

    return () => {
      map.remove();
    };
  }, [data]);

  const setupDeckOverlay = (map) => {
    if (!data || data.length === 0) {
      console.log("Data or path not loaded yet.");
      return;  // Prevent setup if data or paths are not ready
    }
    const layers = [
      new ColumnLayer(layerConfigs.cepLayer),
      new PathLayer(layerConfigs.cepGpsPathLayer),
    ];
    const deckOverlay = new MapboxOverlay({ layers });
    deckOverlayRefCep.current = deckOverlay;
    map.addControl(deckOverlay);
  };
  
  useEffect(() => {
    if (!deckOverlayRefCep.current || !data || data.length === 0) {
      console.log("Data or path not loaded, or overlay not initialized.");
      return;  // Prevent update if data, paths are not ready or if overlay isn't initialized
    }
    const layers = [
        new ColumnLayer(layerConfigs.cepLayer),
        new PathLayer(layerConfigs.cepGpsPathLayer),
    ];
    deckOverlayRefCep.current.setProps({ layers });
  }, [data]);
  

  return <div ref={mapContainerRefCep} className="h-[50vh] md:h-[100vh] w-full rounded-lg border"></div>;
};

export default CEPMap;
