import React, { useState, useContext } from 'react';
import { DataContext } from './DataContext';

const GpsCheckBox = ({ label, onChange }) => {
  const [checked, setChecked] = useState(false);
  const { setGpsCheckBox } = useContext(DataContext);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setGpsCheckBox(event.target.checked);
    if (onChange) {
      onChange(event.target.checked); // Send the checked status back to the parent component
    }
  };

  return (
    <label className="inline-flex items-center">
      <input
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        className="form-checkbox text-black h-6 w-6 transition duration-150 ease-in-out bg-gray-300 rounded shadow-lg"
      />
      {label && <span className="ml-2 text-black">{label}</span>}
    </label>
  );
};

export default GpsCheckBox;
