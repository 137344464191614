import React, { useContext } from 'react';
import ReactECharts from 'echarts-for-react'; // Correct import for the wrapper
import { DataContext } from './DataContext';

const EChartsScatterPlot = ({ xaxis, yaxis }) => {
    const { data } = useContext(DataContext);

    if (!data || !Array.isArray(data) || data.length === 0) {
        return <p>No data available</p>;
    }

    // Prepare the options for ECharts, specifically for a scatter plot
    const options = {
        title: {
            text: `${yaxis}`
        },
        tooltip: {
            trigger: 'item',
            axisPointer: {
                type: 'cross'
            },
            formatter: function (params) {
                return `${params.seriesName}<br/>${xaxis}: ${params.value[0]}<br/>${yaxis}: ${params.value[1]}`;
            }
        },
        xAxis: {
            type: 'value',
            scale: true,  // This ensures that the scatter plot adjusts scales to your data
            name: xaxis
        },
        yAxis: {
            type: 'value',
            scale: true,  // This ensures that the scatter plot adjusts scales to your data
            name: yaxis
        },
        series: [{
            name: `${yaxis} vs. ${xaxis}`,
            type: 'scatter',
            symbolSize: 1,
            data: data.map(item => [item[xaxis], item[yaxis]]), // Scatter plot data should be an array of arrays
            emphasis: {
                focus: 'series'
            }
        }]
    };

    return (
        <div className="w-full h-full">
            <ReactECharts option={options} className="w-full h-full" />
        </div>
    );
};

export default EChartsScatterPlot;
