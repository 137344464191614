import React from 'react';
import EChartsLineChart from '../EChartsLineChart'; // Import your line chart component
import EChartsScatterPlot from '../EChartsScatterPlot';
import EChartsPolarScatter from '../EChartsPolarScatter';
import EChartsScatterPlot2 from '../EChartsScatterPlot2';

const ChartsPage = () => {
    // Sample axis data, replace these with actual props or state as needed
    const xaxis = 'index';

    return (
        <div className="p-5 bg-gray-100">
            <h1 className="text-3xl font-bold text-center mb-5">Data Dashboard</h1>
            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
                {/* Line Chart */}
                <div className="bg-white p-4 shadow rounded-lg">
                    <EChartsLineChart xaxis={xaxis} yaxis={'altitude'} />
                </div>
                <div className="bg-white p-4 shadow rounded-lg">
                    <EChartsLineChart xaxis={xaxis} yaxis={'running_total_distance'} />
                </div>
                <div className="bg-white p-4 shadow rounded-lg">
                    <EChartsLineChart xaxis={xaxis} yaxis={'converted_velocity'} />
                </div>
                <div className="bg-white p-4 shadow rounded-lg">
                    <EChartsLineChart xaxis={xaxis} yaxis={'drift'} />
                </div>
                <div className="bg-white p-4 shadow rounded-lg">
                    <EChartsScatterPlot xaxis={xaxis} yaxis={'witmotion_heading'} />
                </div>
                <div className="bg-white p-4 shadow rounded-lg">
                    <EChartsScatterPlot xaxis={xaxis} yaxis={'closest_street_heading'} />
                </div>
                <div className="bg-white p-4 shadow rounded-lg">
                    <EChartsScatterPlot xaxis={xaxis} yaxis={'consecutive_handrail_hits'} />
                </div>
                <div className="bg-white p-4 shadow rounded-lg">
                    <EChartsLineChart xaxis={xaxis} yaxis={'drift_delta'} />
                </div>
                <div className="bg-white p-4 shadow rounded-lg">
                    <EChartsScatterPlot2 xaxis={xaxis} yaxis1={'raw_heading'} yaxis2={'witmotion_heading'} />
                </div>
                <div className="bg-white p-4 shadow rounded-lg">
                    <EChartsScatterPlot xaxis={xaxis} yaxis={'witmotion_heading'} />
                </div>
                <div className="bg-white p-4 shadow rounded-lg">
                    <EChartsLineChart xaxis={xaxis} yaxis={'drift_delta'} />
                </div>
                <div className="bg-white p-4 shadow rounded-lg">
                    <EChartsLineChart xaxis={xaxis} yaxis={'drift_change'} />
                </div>
                <div className="bg-white p-4 shadow rounded-lg">
                    <EChartsPolarScatter witmotionColumn="witmotion_heading" gpsColumn="gps_course_over_ground" />  
                </div>
                {/* Placeholder for Bar Chart */}
                {/* <div className="bg-white p-4 shadow rounded-lg">
                    <h2 className="text-xl font-bold mb-3">Bar Chart</h2>
                    <p>Bar chart goes here</p>
                    Implement your Bar Chart Component here
                </div> */}

                {/* Placeholder for Pie Chart */}
                {/* <div className="bg-white p-4 shadow rounded-lg">
                    <h2 className="text-xl font-bold mb-3">Pie Chart</h2>
                    <p>Pie chart goes here</p>
                    Implement your Pie Chart Component here
                </div> */}
            </div>
        </div>
    );
};

export default ChartsPage;
