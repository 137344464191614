import React, { useContext } from 'react';
import ReactECharts from 'echarts-for-react'; // Correct import for the wrapper
import { DataContext } from './DataContext';

const EChartsLineChart = ({ xaxis, yaxis }) => {
    const { data } = useContext(DataContext);

    if (!data || !Array.isArray(data) || data.length === 0) {
        return <p>No data available</p>;
    }

    // Prepare the options for ECharts
    const options = {
        title: {
            text: `${yaxis}`
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: data.map(item => item[xaxis])
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            data: data.map(item => item[yaxis]),
            type: 'line',
            smooth: true
        }]
    };

    return (
        <div className="h-full">
            <ReactECharts option={options} className="w-full h-full" />
        </div>
    );
};

export default EChartsLineChart;
