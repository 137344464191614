import React, { useContext } from 'react';
import ReactECharts from 'echarts-for-react'; // Correct import for the wrapper
import { DataContext } from './DataContext';

const EChartsScatterPlot2 = ({ xaxis, yaxis1, yaxis2 }) => {
    const { data } = useContext(DataContext);

    if (!data || !Array.isArray(data) || data.length === 0) {
        return <p>No data available</p>;
    }

    // Prepare the options for ECharts, specifically for a polar plot
    const options = {
        polar: {
            center: ['50%', '50%'],
            radius: '75%'
        },
        angleAxis: {
            type: 'value',
            startAngle: 0,
            max: 360 // Ensure the angle axis goes up to 360 degrees
        },
        radiusAxis: {
            min: 0
        },
        series: [
            {
                coordinateSystem: 'polar',
                name: `${yaxis1}`,
                type: 'line',
                data: data.map(item => [item['witmotion_heading'], item[yaxis1] % 360]),
                itemStyle: {
                    color: 'red'
                }
            },
            {
                coordinateSystem: 'polar',
                name: `${yaxis2}`,
                type: 'line',
                data: data.map(item => [item['witmotion_heading'], item[yaxis2] % 360]),
                itemStyle: {
                    color: 'black'
                }
            }
        ]
    };

    return (
        <div style={{ height: '100vh', width: '100vw' }}>
            <ReactECharts option={options} style={{ height: '100%', width: '100%' }} />
        </div>
    );
};

export default EChartsScatterPlot2;
