import React from 'react';

const UploadProgress = ({ isUploading }) => {
  if (!isUploading) return null;

  return (
    <div className="loading-indicator mt-6"></div>
  );
};

export default UploadProgress;
