import React, { createContext, useState } from 'react';

// Create context
export const DataContext = createContext();

// Create provider
export const DataProvider = ({ children }) => {
  // Initialize state directly in the component
  const [data, setData] = useState([]);
  const [minAltitude, setMinAltitude] = useState(0);
  const [firstTimestamp, setFirstTimestamp] = useState(0);
  const [path, setPath] = useState([]);
  const [pathIndexes, setPathIndexes] = useState([]);
  const [time, setTime] = useState(0);
  const [indexSlider, setIndexSlider] = useState(0);
  const [pathCheckBox, setPathCheckBox] = useState(false);
  const [speed, setSpeed] = useState(1);
  const [followCheckBox, setFollowCheckBox] = useState(false);
  const [totalDistance, setTotalDistance] = useState(0);
  const [pitch, setPitch] = useState(75);
  const [zoom, setZoom] = useState(17);
  const [mileMarkers, setMileMarkers] = useState([]);
  const [gpsCheckBox, setGpsCheckBox] = useState(false);
  const [handrailCoordinates, setHandrailCoordinates] = useState([0, 0]);
  const [handrailCheckBox, setHandrailCheckBox] = useState(false);
  const [speedCheckBox, setSpeedCheckBox] = useState(false);

  // Bundle state and setters together for context value
  const value = {
    data, setData,
    minAltitude, setMinAltitude,
    firstTimestamp, setFirstTimestamp,
    path, setPath,
    pathIndexes, setPathIndexes,
    time, setTime,
    indexSlider, setIndexSlider,
    pathCheckBox, setPathCheckBox,
    speed, setSpeed,
    followCheckBox, setFollowCheckBox,
    totalDistance, setTotalDistance,
    pitch, setPitch,
    zoom, setZoom,
    mileMarkers, setMileMarkers,
    gpsCheckBox, setGpsCheckBox,
    handrailCoordinates, setHandrailCoordinates,
    handrailCheckBox, setHandrailCheckBox,
    speedCheckBox, setSpeedCheckBox
  };

  return (
    <DataContext.Provider value={value}>
      {children}
    </DataContext.Provider>
  );
};
