import React, { useState, useContext, useEffect } from 'react';
import { DataContext } from './DataContext';
import PathCheckBox from './PathCheckBox';
import GpsCheckBox from './GpsCheckBox';
import HandrailCheckBox from './HandrailCheckBox';
import SpeedCheckBox from './SpeedCheckBox';

const MapControls = () => {
  const [value, setValue] = useState(0);
  const [pitchValue, setPitchValue] = useState(75); // State to hold pitch value
  const [zoomValue, setZoomValue] = useState(16); // State to hold pitch value
  const [intervalId, setIntervalId] = useState(null);
  const [speedValue, setSpeedValue] = useState(100); // Default to slowest speed (largest value)
  const [intervalSpeed, setIntervalSpeed] = useState(calculateSpeed(100)); // Initialize with the slowest speed
  const { data, setIndexSlider, setPitch, totalDistance, indexSlider, setZoom, setPathCheckBox, zoom, pitch, setSpeedCheckBox } = useContext(DataContext);

  const driftValues = data.map(item => parseFloat(item.drift)).filter(item => !isNaN(item) && item !== 0);

  const medianDrift = calculateTrimmedMean(driftValues, 0.4); // Trim 10% from both ends

  function calculateSpeed(speedValue) {
    const max = 1000;
    const min = 0.0001;
    return min + (max - min) * (100 - speedValue) / 99;
  }

  const handleSliderChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    updateValue(newValue);
  };

  const handleSpeedChange = (event) => {
    const newSpeedValue = parseInt(event.target.value, 10);
    setSpeedValue(newSpeedValue);
    setIntervalSpeed(calculateSpeed(newSpeedValue));
  };

  const handlePitchChange = (event) => {
    const newPitch = parseInt(event.target.value, 10);
    setPitchValue(newPitch);
    setPitch(newPitch); // Update pitch in the global context
  };

  const handleZoomChange = (event) => {
    const newZoom = parseInt(event.target.value, 10);
    setZoomValue(newZoom);
    setZoom(newZoom); // Update pitch in the global context
  };

  const updateValue = (newValue) => {
    if (!isNaN(newValue) && newValue >= 0 && newValue <= data.length) {
      setValue(newValue);
      setIndexSlider(newValue);
    }
  };

  const startSlider = () => {
    stopSlider(); // Ensure any existing interval is cleared
    setPathCheckBox(true);
    const id = setInterval(() => {
      setValue((currentValue) => {
        const newValue = currentValue >= data.length - 1 ? 0 : currentValue + 2;
        updateValue(newValue);
        return newValue;
      });
    }, intervalSpeed);
    setIntervalId(id);
  };

  const stopSlider = () => {
    if (intervalId) {
      setPathCheckBox(false);
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  // useEffect(() => {
  //   if (intervalId) {
  //     startSlider(); // Restart the slider with the new speed
  //   }
  // }, [intervalSpeed]);

  // useEffect(() => {
  //   return () => intervalId && clearInterval(intervalId);
  // }, [intervalId]);

  return (
    <div className="flex flex-col space-y-6 p-4 w-full bg-gray-800/70 backdrop-blur-sm border border-gray-200 shadow-lg rounded-lg max-w-screen-xl md:max-w-lg h-full">
        <div className="grid grid-cols-2 gap-y-6 gap-x-2 mb-4">
          <button onClick={startSlider} className="bg-blue-800 text-white rounded hover:bg-blue-700 w-full">
            Start Animation
          </button>
          <button onClick={stopSlider} className="bg-red-700 text-white rounded hover:bg-red-800 w-full">
            Stop
          </button>
          <PathCheckBox label="Follow" />
          <GpsCheckBox label="Show GPS" />
          {/* <HandrailCheckBox label="Handrail" /> */}
          <SpeedCheckBox label="Speed" />
        </div>
        <div className="grid grid-cols-6 gap-6 ">
          <label htmlFor="pitchSlider" className="text-white font-mono text-xs col-span-1">
            Map Pitch:
          </label>
          <input
            id="pitchSlider"
            type="range"
            min="0"
            max="90"
            value={pitch}
            onChange={handlePitchChange}
            className="slider my-auto col-span-4"
          />
          <p className="text-white font-mono text-xs my-auto text-right col-span-1">{pitch.toFixed(0)}°</p>
        </div>
        <div className="grid grid-cols-6 gap-6 ">
          <label htmlFor="zoomSlider" className="text-white font-mono text-xs col-span-1">
            Map Zoom:
          </label>
          <input
            id="zoomSlider"
            type="range"
            min="1"
            max="19"
            value={zoom}
            onChange={handleZoomChange}
            className="slider my-auto col-span-4"
          />
          <p className="text-white font-mono text-xs my-auto text-right col-span-1">{zoom.toFixed(1)}</p>
        </div>
        <div className="grid grid-cols-6 gap-6 ">
          <label htmlFor="timeSlider" className="text-white font-mono text-xs cols-span-1">
            Route Progress:
          </label>
          <input
            id="timeSlider"
            type="range"
            min="0"
            max={data.length - 1}
            value={value}
            onChange={handleSliderChange}
            className="slider my-auto col-span-4"
          />
          <p className="text-white font-mono text-xs my-auto text-right col-span-1">{data.length > 0 ? data[indexSlider]?.running_total_distance.toFixed(2) : "0"} Miles</p>
        </div>
        <div className="grid grid-cols-6 gap-6 ">
          <label htmlFor="speedSlider" className="text-white font-mono text-xs col-span-1">
            Playback Speed:
          </label>
          <input
            id="speedSlider"
            type="range"
            min="1"
            max="100"
            value={speedValue}
            onChange={handleSpeedChange}
            className="slider my-auto col-span-4"
          />
          <p className="text-white font-mono text-xs my-auto text-right col-span-1">{intervalSpeed.toFixed(4)} ms</p>
        </div>
        <p className="mr-4 text-white font-mono text-xs w-full">Point: {value}</p>
        <p className="mr-4 text-white font-mono text-xs w-full">
          Timestamp: {data[value] ? new Date(data[value].timestamp).toLocaleString() : 'N/A'}
        </p>
        {/* <p className="mr-4 text-white font-mono text-xs w-full">Altitude: {data[value]?.altitude}</p> */}
        {/* <p className="mr-4 text-white font-mono text-xs w-full">Dist to GPS: {data[value]?.drift} Meters</p> */}
        <p className="mr-4 text-white font-mono text-xs w-full">Miles: {totalDistance.toFixed(2)}</p>
        {/* <p className="mr-4 text-white font-mono text-xs w-full">Zoom: {zoom}</p> */}
        {/* <p className="mr-4 text-white font-mono text-xs w-full">Pitch: {pitch}</p> */}
        {/* <p className="mr-4 text-white font-mono text-xs w-full">Total Drift at Destination: {data[data.length-1]?.drift} Meters</p> */}
        {/* <p className="mr-4 text-white font-mono text-xs w-full">Median Drift: {medianDrift.toFixed(2)} Meters</p> */}
        {/* <p className="mr-4 text-white font-mono text-xs w-full">Drift Rate: {(data[data.length-1]?.drift/totalDistance).toFixed(2)} Meters per Mile</p> */}
    </div>
  );
};

export default MapControls;

function calculateTrimmedMean(values, trimFraction) {
  if (values.length === 0) return 0;

  // Sorting values to trim the ends
  values.sort((a, b) => a - b);
  const trimCount = Math.floor(values.length * trimFraction);

  // Slicing the array to remove the lower and upper portions
  const trimmedValues = values.slice(0, values.length - trimCount);

  // Calculating the mean of the remaining values
  const sum = trimmedValues.reduce((acc, value) => acc + value, 0);
  return sum / trimmedValues.length;
}
