import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { DataProvider } from './DataContext'; // Import your DataProvider
import HomePage from './pages/HomePage';
import Charts from './pages/Charts';
import FlowbiteNavBar from './NavBar'
import DriftPage from './pages/Drift';
import CEPPage from './pages/CEP';
// import AboutPage from './pages/AboutPage';
// import ContactPage from './pages/ContactPage';
// import NotFoundPage from './pages/NotFoundPage';

function App() {
  return (
    <Router>
      <DataProvider> {/* Wrapping all routes inside the DataProvider */}
        <FlowbiteNavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/charts" element={<Charts />} />
          <Route path="/drift" element={<DriftPage />} />
          <Route path="/cep" element={<CEPPage />} />
          {/* <Route path="*" element={<NotFoundPage />} /> */}
        </Routes>
      </DataProvider>
    </Router>
  );
}

export default App;
