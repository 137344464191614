import React, { useContext } from 'react';
import ReactECharts from 'echarts-for-react'; // Correct import for the wrapper
import { DataContext } from './DataContext';

const CepLineChart = () => {
    const { data } = useContext(DataContext);

    if (!data || !Array.isArray(data) || data.length === 0) {
        return <p>No data available</p>;
    }
    
    // Calculate the maximum cep_drift value
    const maxCepDrift = Math.max(...data.map(item => item.cep_drift));

    // Compute median of cep_drift
    const driftValues = data.map(item => item.cep_drift).sort((a, b) => a - b);
    const middleIndex = Math.floor(driftValues.length / 2);
    const medianCepDrift = driftValues.length % 2 !== 0 ? driftValues[middleIndex] : (driftValues[middleIndex - 1] + driftValues[middleIndex]) / 2;

    // Compute median of drift
    const driftSorted = data.map(item => item.drift).sort((a, b) => a - b);
    const medianIndex = Math.floor(driftSorted.length / 2);
    const medianDrift = driftSorted.length % 2 !== 0 ? driftSorted[medianIndex] : (driftSorted[medianIndex - 1] + driftSorted[medianIndex]) / 2;


    // Get the total time from the last item in the data array
    const lastTimeValue = data[data.length - 1]['time_elapsed'];
    const totalHours = Math.floor(lastTimeValue);
    const totalMinutes = Math.round((lastTimeValue - totalHours) * 60);

    const referenceValue = 518.56; // Example reference line value

    // Calculate the number of points above and below the reference line
    const pointsBelow = data.filter(item => item['cep_drift'] <= referenceValue).length;
    const pointsAbove = data.length - pointsBelow;
    const percentageBelow = (pointsBelow / data.length) * 100;

    const slopeData = data.map(item => ({
        value: item['time_elapsed'] * referenceValue
    }));

    // Function to calculate median CEP50
    const calculateCEP50 = (data) => {
        const sortedDrifts = data.map(item => item.cep_drift).sort((a, b) => a - b);
        const midIndex = Math.floor(sortedDrifts.length / 2);
        return sortedDrifts.length % 2 !== 0 
        ? sortedDrifts[midIndex] 
        : (sortedDrifts[midIndex - 1] + sortedDrifts[midIndex]) / 2;
    };
    
    // Segmenting data into hourly buckets and calculating CEP50
    const hourlyCEP50 = [];
    let previousCEP50 = null;
    let currentHour = Math.floor(data[0].time_elapsed);
    let currentHourData = [];
    
    data.forEach(item => {
        if (Math.floor(item.time_elapsed) === currentHour) {
        currentHourData.push(item);
        } else {
        const cep50Meters = calculateCEP50(currentHourData);
        const cep50NauticalMiles = cep50Meters / 1852;
        const rateOfChange = previousCEP50 !== null ? cep50NauticalMiles - previousCEP50 : 0;
    
        hourlyCEP50.push({
            hour: currentHour,
            cep50NauticalMiles,
            rateOfChange
        });
    
        // Reset for next hour
        previousCEP50 = cep50NauticalMiles;
        currentHour = Math.floor(item.time_elapsed);
        currentHourData = [item];  // Start new hour bucket
        }
    });
    
    // Process the last hour's data
    if (currentHourData.length > 0) {
        const cep50Meters = calculateCEP50(currentHourData);
        const cep50NauticalMiles = cep50Meters / 1852;
        const rateOfChange = previousCEP50 !== null ? cep50NauticalMiles - previousCEP50 : 0;
    
        hourlyCEP50.push({
        hour: currentHour,
        cep50NauticalMiles,
        rateOfChange
        });
    }

    const graphic = {
        type: 'group',
        left: '20%',  // Position from the left edge of the chart
        top: '10%',   // Position from the top edge of the chart
        z: 1000,        // Ensure it's on top of other chart elements
        children: [
            {
                type: 'rect',  // Background rectangle
                left: 'center',
                top: 'middle',
                z: 100,
                shape: {
                    width: 400,
                    height: 150
                },
                style: {
                    fill: '#fff',  // White background
                    stroke: '#aaa', // Gray border
                    lineWidth: 1,
                    shadowBlur: 10,
                    shadowOffsetX: 3,
                    shadowOffsetY: 3,
                    shadowColor: 'rgba(0,0,0,0.3)'
                }
            },
            {
                type: 'text',  // Text element for the definition
                left: 'center',
                top: 'middle',
                z: 101,
                style: {
                    text: `CEP 50: Radius within which 50% of data points fall \nwithin XX Nautical Miles of Ground Truth. \nA CEP 50 of 1NM means that 50% \nof the data points fall within 1NM of the \nground truth and 50% fall outside of 1NM. \nThe raw data had a CEP 50 of ${(medianCepDrift / 1852).toFixed(2)}NM \nand the MSRS system had a CEP 50 of ${(medianDrift / 1852).toFixed(2)}NM.`,
                    textAlign: 'left',
                    fill: '#333', // Text color
                    font: '14px Arial',
                    textWidth: 280,  // Wrap text within 280 pixels
                    textLineHeight: 20,
                    rich: {
                        title: {
                            color: '#000',
                            fontWeight: 'bold'
                        }
                    }
                }
            }
        ]
    };

    // Prepare the options for ECharts
    const options = {
        title: {
            text: `CEP Error Comparison`,
            subtext: ``,
            left: 'center',
            textStyle: {
                color: '#333',
                fontSize: 20,
                fontWeight: 'bold'
            }
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        legend: {
            data: ['cep_drift', 'drift'],
            left: 'right', // Can be 'center', 'right', or a specific percentage (e.g., '10%')
            top: 'top', // Can also be 'middle', 'bottom', or a specific percentage
            orient: 'vertical', // Can be 'horizontal'
            padding: 5, // Adjusts the padding around the legend. Can be a single number or an array [top, right, bottom, left]
            itemGap: 10, // The gap between legend items, in pixels
            textStyle: {
                color: '#333', // Changes the text color
                fontFamily: 'Arial', // Sets the font family
                fontSize: 14, // Sets the font size
                fontWeight: 'bold' // Sets the font weight
            },
            itemWidth: 25, // Width of the legend symbol
            itemHeight: 14, // Height of the legend symbol
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Background color of the legend, with some transparency
            borderColor: '#ccc', // Border color of the legend
            borderWidth: 1, // Border width of the legend
            borderRadius: 4, // Border radius of the legend box
            // selector: [ // Optional selector buttons, useful in responsive designs or large datasets
            //     {
            //         type: 'all or inverse',
            //         title: 'Select All/Inverse'
            //     }
            // ],
            // selectedMode: 'multiple', // Can be false, 'single', or 'multiple' for toggling series on/off
            tooltip: { // Tooltip for the legend, useful for providing more context
                show: true,
                formatter: function (name) {
                    return 'Click to show/hide'; // Custom tooltip content
                }
            }
        },        
        xAxis: {
            type: 'category',
            data: data.map(item => item['time_elapsed']),
            name: `Hours (Total: ${totalHours}h ${totalMinutes}m)`,
            nameLocation: 'middle',
            nameGap: 30,
            nameTextStyle: {
                fontSize: 16,
                padding: [0, 0, 20, 0]
            }
        },
        yAxis: {
            type: 'value',
            name: 'Drift in Meters',
            nameLocation: 'middle', // Ensures that the name is aligned at the middle of the axis
            nameTextStyle: {
                fontSize: 16,
                align: 'left' // Ensure the text is aligned appropriately
            },
            nameRotate: 90, // Rotates the name to be parallel to the Y-axis
            nameGap: 40, // Increase the gap if needed to move the title further from the axis labels
            axisLabel: {
                formatter: '{value} m',
                margin: -20 // Adjust this value to manage space between labels and the axis
            },
            min: 0,
            max: maxCepDrift
        },
        grid: {
            left: '5%', // Increase the left margin if necessary to give more space for labels
            right: '5%',
            bottom: '10%',
            containLabel: true
        },
        
        graphic: graphic,
        series: [
            {
                name: 'cep_drift',
                data: data.map(item => item['cep_drift']),
                type: 'line',
                smooth: true,
                symbol: 'circle',
                symbolSize: 8,
                showSymbol: false
            },
            {
                name: 'drift',
                data: data.map(item => item['drift']),
                type: 'line',
                smooth: true,
                symbol: 'rectangle',
                symbolSize: 8,
                showSymbol: false,
                itemStyle: {
                    color: '#ff7f50'
                }
            },
            {
                name: 'Reference Line',
                type: 'line',
                data: [],
                markLine: {
                    silent: true,
                    label: {
                        formatter: 'CEP 50 baseline at .23NM (518.56m)',
                        position: 'end',
                        color: 'black',
                        fontSize: 12,
                        fontWeight: 'bold',
                        align: 'right',
                        padding: 3,
                        backgroundColor: 'white',
                        borderWidth: 1,
                        borderColor: 'red',
                        borderRadius: 5,
                        lineHeight: 30,
                        
                    },
                    data: [{
                        yAxis: 518.56
                    }],
                    lineStyle: {
                        color: 'red', // Change this to any color you like
                        type: 'solid',
                        width: 5
                    }
                }
            },
            {
                name: 'Median Cep Drift',
                type: 'line',
                data: [],
                markLine: {
                    silent: true,
                    label: {
                        formatter: `CEP 50 Raw Data: ${(medianCepDrift / 1852).toFixed(2)}NM (${medianCepDrift}m)`,
                        position: 'end',
                        color: 'black',
                        fontSize: 12,
                        fontWeight: 'bold',
                        align: 'right',
                        padding: 3,
                        backgroundColor: 'white',
                        borderWidth: 1,
                        borderColor: 'red',
                        borderRadius: 5,
                        lineHeight: 30,
                        
                    },
                    symbol: 'none',
                    data: [{ yAxis: medianCepDrift }],
                    lineStyle: {
                        color: 'blue',
                        type: 'dashed',
                        width: 2
                    }
                }
            },
            {
                name: 'Median Drift',
                type: 'line',
                data: [],
                markLine: {
                    silent: true,
                    label: {
                        formatter: `CEP 50 MSRS System: ${(medianDrift / 1852).toFixed(2)}NM (${medianDrift}m)`,
                        position: 'end',
                        color: 'black',
                        fontSize: 12,
                        fontWeight: 'bold',
                        align: 'right',
                        padding: 3,
                        backgroundColor: 'white',
                        borderWidth: 1,
                        borderColor: 'red',
                        borderRadius: 5,
                        lineHeight: 30,
                        
                    },
                    symbol: 'none',
                    data: [{ yAxis: medianDrift }],
                    lineStyle: {
                        color: 'blue',
                        type: 'dashed',
                        width: 2
                    }
                }
            },
            {
                name: 'CEP 50 Rate',
                type: 'line',
                data: hourlyCEP50.map(item => ({ value: item.rateOfChange, name: `Hour ${item.hour}` })),
                label: {
                    show: true,
                    formatter: '{c} NM/h'
                },
            },
            // {
            //     name: 'Slope Line',
            //     data: slopeData,
            //     type: 'line',
            //     smooth: false, // Set to false to clearly see the linear increase
            //     symbol: 'none', // No symbols at data points
            //     lineStyle: {
            //         normal: {
            //             color: 'blue', // Choose a distinct color
            //             width: 2
            //         }
            //     }
            // },
        ]
    };

    return (
        <div className="bg-purple-100 h-screen flex flex-col justify-center items-center rounded-lg">
            <ReactECharts option={options} style={{ height: '100%', width: '100%', padding: '10px' }} />
            {/* <div className="text-center mt-4 p-4 bg-white shadow-lg rounded-md w-full md:w-1/2 lg:w-1/3">
                <p>Points above the line: {pointsAbove}</p>
                <p>Points below the line: {pointsBelow}</p>
                <p>Percentage below the line: {percentageBelow.toFixed(2)}%</p>
            </div> */}
        </div>
    );
};

export default CepLineChart;
