import React, { useRef, useState, useEffect, useContext } from 'react';
import mapboxgl from 'mapbox-gl';
import { MapboxOverlay } from '@deck.gl/mapbox';
import { ColumnLayer, TextLayer, PathLayer } from '@deck.gl/layers';
import { TripsLayer } from '@deck.gl/geo-layers'; // Corrected import for TripsLayer
import 'mapbox-gl/dist/mapbox-gl.css';
import { DataContext } from './DataContext';

const MapComponent = () => {
  const mapContainerRef = useRef(null);
  const deckOverlayRef = useRef(null);
  const mapRef = useRef(null);
  const [isAnimating, setIsAnimating] = useState(true);
  const { data, path, minAltitude, indexSlider, pathCheckBox, pitch, zoom, setPitch, setZoom, mileMarkers, gpsCheckBox, handrailCoordinates, handrailCheckBox, speedCheckBox } = useContext(DataContext);

  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

  // Function to interpolate between two colors based on a value
  function interpolateColor(minColor, maxColor, minVal, maxVal, val) {
    const ratio = Math.min(Math.max((val - minVal) / (maxVal - minVal), 0), 1);
    const inverseRatio = 1 - ratio;
    return [
      Math.round(minColor[0] * inverseRatio + maxColor[0] * ratio),
      Math.round(minColor[1] * inverseRatio + maxColor[1] * ratio),
      Math.round(minColor[2] * inverseRatio + maxColor[2] * ratio)
    ];
  }


  // Define layer configurations in a dictionary
  const layerConfigs = {
    msrsLayer: {
      id: 'msrs-layer',
      data,
      getPosition: d => [d.msrs_lng, d.msrs_lat],
      getFillColor: [100,46,92],
      getElevation: d => d.altitude - minAltitude,
      elevationScale: 0.1,
      diskResolution: 12,
      radius: 2,
      extruded: true,
      pickable: true
    },
    msrsPathLayer: {
      id: 'msrs-path-layer',
      data: [path[0]],  // This should be the data set from your state or context that includes the path information.
      getPath: d => d.path,  // Assuming each data entry has a 'path' property which is an array of coordinates.
      getColor: [255,0,255],  // Assuming each data entry includes a 'color' property defining the path color.
      getElevation: d => 1,  // Assuming the path is flat on the ground
      widthUnits: 'meters',
      widthScale: 5,  // Adjust this value based on the desired thickness of the path.
      opacity: 1,
      pickable: true,
      widthMinPixels: 2,  // Ensures the line width does not go below 2 pixels
    },
    gpsPathLayer: {
      id: 'gps-path-layer',
      data: [path[1]],  // This should be the data set from your state or context that includes the path information.
      getPath: d => d.path,  // Assuming each data entry has a 'path' property which is an array of coordinates.
      getColor: d => d.color,  // Assuming each data entry includes a 'color' property defining the path color.
      getElevation: d => 0,  // Assuming the path is flat on the ground
      widthUnits: 'meters',
      widthScale: 5,  // Adjust this value based on the desired thickness of the path.
      opacity: 1,
      pickable: true,
      widthMinPixels: 2,  // Ensures the line width does not go below 2 pixels
    },
    gpsLayer: {
      id: 'gps-layer',
      data,
      getPosition: d => [d.gps_lng, d.gps_lat],
      getFillColor: [118,220,218],
      getElevation: d => d.altitude - minAltitude,
      elevationScale: 0.1,
      diskResolution: 12,
      radius: 2,
      extruded: true,
      pickable: true
    },
    mileMarkerLayer: {
      id: 'MileMarker-layer',
      data: mileMarkers, // Ensure this data is an array of objects with latitude and longitude
      getPosition: d => [d.longitude, d.latitude],
      getFillColor: [255, 255, 0], // Bright yellow
      getElevation: d => 0, // Static elevation of 60 meters for each disk
      elevationScale: 0.1,
      diskResolution: 4, // Number of segments in the disk, more means smoother disk
      radius: 20, // Radius of the disks in meters
      extruded: true, // Set to true to enable 3D extrusions
      pickable: true // Allows the layer to respond to mouse events
    },
    tripsLayer: {
      id: 'trips-layer',
      data: [path[2]],
      getPath: d => d.path,
      getTimestamps: d => d.timestamps,
      getColor: d => [200,0,200],
      opacity: 1,
      widthMinPixels: 10,
      trailLength: 2000,
      currentTime: indexSlider,
      shadowEnabled: true,
      fadeTrail: true,
      jointRounded: true,
      capRounded: true
    },
    gpsTripsLayer: {
      id: 'gps-trips-layer',
      data: [path[3]],
      getPath: d => d.path,
      getTimestamps: d => d.timestamps,
      getColor: d => d.color,
      opacity: 1,
      widthMinPixels: 10,
      trailLength: 2000,
      currentTime: indexSlider,
      shadowEnabled: true,
      fadeTrail: true,
      jointRounded: true,
      capRounded: true
    },
    mileMarkerTextLayer: {
      id: 'mile-marker-text-layer',
      data: mileMarkers, // Ensure this data is updated with the new labels
      getPosition: d => [d.longitude, d.latitude], // Fetch position of each marker
      getText: d => d.label, // Use the label for displaying text
      getColor: d => [255, 0, 0, 255], // Set text color, here it's red fully opaque
      getSize: d => 16, // Font size of the text
      getAngle: d => 0, // Angle of the text in degrees
      getTextAnchor: 'start', // Horizontal alignment of text: 'start', 'middle', 'end'
      getAlignmentBaseline: 'bottom', // Vertical alignment: 'top', 'center', 'bottom'
      billboard: true, // Ensure text faces the camera
      sizeScale: 1,
      sizeUnits: 'meters', // Font size units, can be 'pixels', 'meters'
      pickable: true // Makes the text interactive (clickable and/or hoverable)
    },
    handrailPathLayer: {
      id: 'handrail-path-layer',
      data: handrailCoordinates['coordinates'],
      getPosition: d => [d[0], d[1]],
      getFillColor: d => d[2] > 0 ? [0,255,255] : [255,255,0], 
      getElevation: d => d[2] > 0 ? d[2] : Math.abs(d[2]),
      elevationScale: 10,
      diskResolution: 20, // Number of segments in the disk, more means smoother disk
      radius: 1, // Radius of the disks in meters
      extruded: true, // Set to true to enable 3D extrusions
      pickable: true // Allows the layer to respond to mouse events
    },
    speedPathLayer: {
      id: 'msrs-speed-layer',
      data,
      getPosition: d => [d.msrs_lng, d.msrs_lat],
      getFillColor: d => interpolateColor([0, 0, 255], [255, 0, 0], 0, 60, d.jetson_doppler_speed),
      getElevation: d => d.jetson_doppler_speed,
      elevationScale: 1,
      diskResolution: 12,
      radius: 2,
      extruded: true,
      pickable: true,
      wireFrame: true
    },
  };

  useEffect(() => {
    if (!mapContainerRef.current) return;
    const defaultCoordinates = [-80, 34]; // Fallback coordinates
    const initialCoordinates = data && data.length > 0 ? [data[0].msrs_lng, data[0].msrs_lat] : defaultCoordinates;
  
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/frstylskier/clvd3y7d801gc01ql57zv1uos',
      accessToken: MAPBOX_TOKEN,
      center: initialCoordinates,
      zoom: data && data.length > 0 ? 14 : 2,
      pitch: data && data.length > 0 ? 15 : 0,
    });
    mapRef.current = map;

    const handleZoomStart = () => setIsAnimating(false);
    const handlePitchStart = () => setIsAnimating(false);
  
    const handleZoomEnd = () => {
      setIsAnimating(true);
      setZoom(map.getZoom()); // Update context zoom state
    };
  
    const handlePitchEnd = () => {
      setIsAnimating(true);
      setPitch(map.getPitch()); // Update context pitch state
    };

    const handleClick = () => {
      setIsAnimating(false);
      // setPathCheckBox(false); // Disable follow mode when user interacts with the map
    };

    map.on('load', () => {
      // map.addControl(new mapboxgl.NavigationControl(), 'top-right');
      map.on('zoomstart', handleZoomStart);
      map.on('pitchstart', handlePitchStart);
      map.on('zoomend', handleZoomEnd);
      map.on('pitchend', handlePitchEnd);
      map.on('click', handleClick);
      setupDeckOverlay(map);
    });

    return () => {
      map.off('zoomstart', handleZoomStart);
      map.off('pitchstart', handlePitchStart);
      map.off('zoomend', handleZoomEnd);
      map.off('pitchend', handlePitchEnd);
      map.off('click', handleClick);
      map.remove();
    };
  }, [data]);

  const setupDeckOverlay = (map) => {
    if (!data || !path || data.length === 0 || path.length === 0) {
      console.log("Data or path not loaded yet.");
      return;  // Prevent setup if data or paths are not ready
    }
  
    const layers = [
      new TripsLayer(layerConfigs.tripsLayer),
      new TripsLayer(layerConfigs.gpsTripsLayer),
      new ColumnLayer(layerConfigs.mileMarkerLayer),
      new TextLayer(layerConfigs.mileMarkerTextLayer),
    ];
  
    if (!pathCheckBox) {
      layers.push(new PathLayer(layerConfigs.msrsPathLayer));
      if (gpsCheckBox) {
        layers.push(new PathLayer(layerConfigs.gpsPathLayer));
      }
    }

    if (handrailCheckBox) {
      layers.push(new ColumnLayer(layerConfigs.handrailPathLayer));
    }

    if (speedCheckBox) {
      layers.push(new ColumnLayer(layerConfigs.speedPathLayer));
    }
  
    const deckOverlay = new MapboxOverlay({ layers });
    deckOverlayRef.current = deckOverlay;
    map.addControl(deckOverlay);
  };
  
  useEffect(() => {
    if (!deckOverlayRef.current || !data || !path || data.length === 0 || path.length === 0) {
      console.log("Data or path not loaded, or overlay not initialized.");
      return;  // Prevent update if data, paths are not ready or if overlay isn't initialized
    }
  
    const layers = [
      new TripsLayer({...layerConfigs.tripsLayer, currentTime: indexSlider}),
      new TripsLayer({...layerConfigs.gpsTripsLayer, currentTime: indexSlider}),
      new ColumnLayer(layerConfigs.mileMarkerLayer),
      new TextLayer(layerConfigs.mileMarkerTextLayer),
    ];
  
    if (!pathCheckBox) {
      layers.push(new PathLayer(layerConfigs.msrsPathLayer));
      if (gpsCheckBox) {
        layers.push(new PathLayer(layerConfigs.gpsPathLayer));
      }
    }

    if (handrailCheckBox) {
      layers.push(new ColumnLayer(layerConfigs.handrailPathLayer));
    }

    if (speedCheckBox) {
      layers.push(new ColumnLayer(layerConfigs.speedPathLayer));
    }
  
    deckOverlayRef.current.setProps({ layers });
  }, [indexSlider, data, path, pathCheckBox, gpsCheckBox, handrailCheckBox, speedCheckBox]);
  
  

  useEffect(() => {
    if (pathCheckBox && mapRef.current && isAnimating) {
      const coordinate = path[0].path[indexSlider];
      if (coordinate) {
        mapRef.current.easeTo({
          center: coordinate,
          essential: true,
          speed: 1,
          duration: 100,
          bearing: pitch < 60 ? 0 : data[indexSlider]['camera_heading'],
          pitch: pitch,
          zoom: zoom,
        });
      }
    }
  }, [indexSlider, pathCheckBox, pitch, zoom, isAnimating]); // Added isAnimating to the dependency array  

  return <div ref={mapContainerRef} className="h-[50vh] md:h-[100vh] w-full rounded-lg mr-4 border"></div>;
};

export default MapComponent;
