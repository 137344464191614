import React, { useContext } from 'react';
import ReactECharts from 'echarts-for-react'; // Ensure ECharts is properly imported
import { DataContext } from './DataContext';
import { color } from 'echarts';

const EChartsPolarScatter = ({ witmotionColumn, gpsColumn }) => {
    const { data } = useContext(DataContext);

    if (!data || !Array.isArray(data) || data.length === 0) {
        return <p>No data available</p>;
    }

    const angleDifference = (angle1, angle2) => {
        const diff = (angle1 - angle2 + 360) % 360;
        return diff > 180 ? 360 - diff : diff;
    };

    // Prepare the options for ECharts using a polar coordinate system
    const options = {
        title: {
            text: 'Heading Comparison'
        },
        tooltip: {
            trigger: 'item',
            formatter: function (params) {
                return `${params.seriesName}<br>Radius: ${params.value[0]}<br>Angle: ${params.value[1]}`;
            }
        },
        polar: {},
        angleAxis: {
            type: 'value',
            min: 0,
            max: 360,  // Ensuring the angle axis goes from 0 to 360 degrees
            startAngle: 0,
            splitNumber: 4,  // Define how many splits there are, adjust according to need
            axisLine: {show: true},
            axisLabel: {formatter: '{value}°'}
        },
        radiusAxis: {
            min: 0,
            max: 6,  // Setting the maximum radius to 3
            axisLine: {show: true},
            axisLabel: {formatter: '{value}'}
        },
        series: [
            {
                name: 'Witmotion Heading',
                type: 'scatter',
                coordinateSystem: 'polar',
                symbolSize: 1,
                color: 'blue',
                data: data.map(item => {
                    let witmotion_heading = parseFloat(item[witmotionColumn]);
                    return [1, witmotion_heading]; // Radius of 1, angle is the witmotion heading
                })
            },
            {
                name: 'GPS Heading',
                type: 'scatter',
                coordinateSystem: 'polar',
                symbolSize: 1,
                color: 'red',
                data: data.map(item => {
                    let witmotion_heading = parseFloat(item[witmotionColumn]);
                    let gps_heading = parseFloat(item[gpsColumn]);
                    let radius = angleDifference(witmotion_heading, gps_heading) + 1; // Calculate radius as specified
                    return [radius, witmotion_heading]; // Radius based on the difference, angle is the GPS heading
                })
            }
        ]
    };

    return (
        <div style={{ width: '100%', height: '400px' }}>
            <ReactECharts option={options} style={{ width: '100%', height: '100%' }} />
        </div>
    );
};

export default EChartsPolarScatter;
