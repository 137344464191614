import React from 'react';
import MapComponent from '../Map';
import CSVUpload from '../CSVUpload';
import MapControls from '../MapControls';
import FirebaseStorage from '../FirebaseStorage';
import Footer from '../Footer';



const HomePage = () => {
  return (
    <>
      <div className="relative flex flex-col items-center min-h-screen navbar-bg ">
        {/* <h1 className="bg-blue-500 text-white font-bold text-2xl py-2 px-4 rounded-lg shadow-md mt-4">MSRS Logs Viewer</h1> */}
        <div className="w-full backdrop-blur-sm p-4">
          {/* Layout for CSVUpload and controls, modified for vertical centering */}
          <CSVUpload />
          <div className="flex flex-col md:flex-row justify-between items-top w-full pt-5">
            <MapComponent /> 
            <div className="flex flex-col space-y-4 mt-4 md:mt-0">
              <MapControls />
            </div>
          </div>
          <FirebaseStorage />
        </div>
        <div className='p-0 m-0 w-full'>
          <Footer />
        </div>
      </div>
      </>
  );
};

export default HomePage;

