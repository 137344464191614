import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        // <nav className="bg-[url('../public/mountains.png')] bg-cover bg-bottom border-gray-200 dark:bg-gray-900 h-[400px]">
        <nav className="flex flex-row justify-between bg-white border-gray-200 dark:bg-gray-900">
            <div className="flex flex-row items-center justify-between p-4">
                <Link to="/" className="flex space-x-3">
                    <img src="/MSRS.svg" className="h-8" alt="Logo" />
                    <span className="text-2xl font-semibold whitespace-nowrap dark:text-white hidden md:block">MSRS Data Analytics</span>
                </Link>
                {/* <button onClick={toggleMenu} type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                    </svg>
                </button> */}
            </div>
            <div className={``}>
                <ul className="flex flex-row p-4 mt-4">
                    <li>
                        <Link to="/" className="py-2 px-3 text-gray-900 rounded hover:bg-gray-100" aria-current="page">Map</Link>
                    </li>
                    {/* <li>
                        <Link to="/charts" className="py-2 px-3 text-gray-900 rounded hover:bg-gray-100">Charts</Link>
                    </li>
                    <li>
                        <Link to="/drift" className="py-2 px-3 text-gray-900 rounded hover:bg-gray-100">Drift</Link>
                    </li>
                    <li>
                        <Link to="/cep" className="py-2 px-3 text-white rounded hover:bg-gray-100">CEP</Link>
                    </li> */}
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
