import React from 'react';
import CEPMap from '../CEPMap';
import CepLineChart from '../EChartsCep';
import Footer from '../Footer';



const CEPPage = () => {
  return (
    <>
      <div className="relative flex flex-col items-center min-h-screen navbar-bg ">
        {/* <h1 className="bg-blue-500 text-white font-bold text-2xl py-2 px-4 rounded-lg shadow-md mt-4">MSRS Logs Viewer</h1> */}
        <div className="w-full backdrop-blur-sm p-4">
          {/* Layout for CSVUpload and controls, modified for vertical centering */}
          <div className="flex flex-col md:flex-row justify-between items-top w-full pb-5">
            <CEPMap /> 
          </div>
          <div className=''>
            <CepLineChart xaxis={'time_elapsed'} yaxis={'cep_drift'} additionalYAxis={'drift'} />
          </div>
        </div>
        <div className='p-0 m-0 w-full'>
          <Footer />
        </div>
      </div>
      </>
  );
};

export default CEPPage;

